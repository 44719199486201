import { all, fork, call, put, takeEvery, select } from 'redux-saga/effects';

import * as api from '../api/class';
import { constants } from '../modules/class';

import _ from 'lodash';
import { format } from 'date-fns';
import { notification } from 'antd';
import { getCourserClassId } from '../selectors/course';

// CLASS_GET_LECTURE_DETAILS
function* getLectureDetails(action) {
  try {
    const payload = yield call(api.getLectureDetails, action.classId);
    yield put({
      type: constants.CLASS_GET_LECTURE_DETAILS.SUCCESS,
      payload,
    });
    action.next && action.next(payload);
  } catch (e) {
    yield put({
      type: constants.CLASS_GET_LECTURE_DETAILS.FAILED,
      message: e.message || e,
    });
    // notification['error']({
    //   message: `Problemas ao listar os cursos, por favor contate o administrador.`,
    // });
  }
}

// CLASS_PERSIST_ONGOING_LECTURE
function* persistOngoingLecture(action) {
  try {
    const payload = yield call(
      api.persistOngoingLecture,
      action.userId,
      action.classId,
      action.ongoingPayload
    );
    yield put({
      type: constants.CLASS_PERSIST_ONGOING_LECTURE.SUCCESS,
      payload,
    });
  } catch (e) {
    yield put({
      type: constants.CLASS_PERSIST_ONGOING_LECTURE.FAILED,
      message: e.message || e,
    });
    notification['error']({
      message: `Problemas ao registrar a resposta.`,
    });
  }
}

// CLASS_GET_ONGOING_LECTURE
function* getOngoingLecture(action) {
  try {
    const payload = yield call(
      api.getOngoingLecture,
      action.userId,
      action.classId,
      action.courseTag
    );
    const ongoingLecture = JSON.parse(_.get(payload, '[0].prova', {}));

    yield put({
      type: constants.CLASS_GET_ONGOING_LECTURE.SUCCESS,
      ongoingLecture,
    });
    action.next && action.next(payload);
  } catch (e) {
    yield put({
      type: constants.CLASS_GET_ONGOING_LECTURE.FAILED,
      message: e.message || e,
    });
    // notification['error']({
    //   message: `Problemas ao buscar os dados de andamento da prova, por favor contate o administrador.`,
    // })
  }
}

// CLASS_FINISH_LECTURE
function* finishLecture(action) {
  try {
    const courseClassId = yield select(getCourserClassId);
    const payload = yield call(
      api.finishLecture,
      action.userId,
      action.classId,
      action.finalPayload,
      courseClassId
    );

    const getLecture = (state) => state.lecture;
    const lecture = yield select(getLecture);

    let lectureResult = {
      finishDate: _.get(payload, 'prova_aula.data_prova', format(new Date(), 'yyyy/MM/dd')),
      time: _.get(payload, 'prova_aula.tempo_prova', '00:00:00'),
      totalQuestions: lecture.studentQuestions.length,
      finishedLecture: payload.prova_aula,
    };

    yield put({
      type: constants.CLASS_FINISH_LECTURE.SUCCESS,
      lectureResult,
    });
    action.next && action.next(lectureResult);
  } catch (e) {
    yield put({
      type: constants.CLASS_FINISH_LECTURE.FAILED,
      message: e.message || e,
    });
    notification['error']({
      message: `Problemas ao registrar a resposta.`,
    });
  }
}

// CLASS_PDF_DOWNLOAD
function* registerPdfDownload(action) {
  try {
    const courseClassId = yield select(getCourserClassId);
    const payload = yield call(
      api.registerPdfDownload,
      action.userId,
      action.courseTag,
      action.classId,
      courseClassId
    );
    yield put({
      type: constants.CLASS_PDF_DOWNLOAD.SUCCESS,
      payload,
    });
    action.next && action.next(payload);
  } catch (e) {
    yield put({
      type: constants.CLASS_PDF_DOWNLOAD.FAILED,
      message: e.message || e,
    });
    notification['error']({
      message: `Problemas ao registrar a resposta.`,
    });
  }
}

// CLASS_VIDEO_PLAY
function* registerVideoPlay(action) {
  try {
    const courseClassId = yield select(getCourserClassId);
    const payload = yield call(
      api.registerVideoPlay,
      action.userId,
      action.courseTag,
      action.classId,
      action.videoId,
      courseClassId
    );
    yield put({
      type: constants.CLASS_VIDEO_PLAY.SUCCESS,
      payload,
    });
    action.next && action.next(payload);
  } catch (e) {
    yield put({
      type: constants.CLASS_VIDEO_PLAY.FAILED,
      message: e.message || e,
    });
    notification['error']({
      message: `Problemas ao registrar a resposta.`,
    });
  }
}

// CLASS_GET_FINISHED_LECTURES
function* getFinishedLectures(action) {
  try {
    const payload = yield call(api.getFinishedLectures, action.classId);

    yield put({
      type: constants.CLASS_GET_FINISHED_LECTURES.SUCCESS,
      payload,
    });
    action.next && action.next(payload);
  } catch (e) {
    yield put({
      type: constants.CLASS_GET_FINISHED_LECTURES.FAILED,
      message: e.message || e,
    });
  }
}

// CLASS_GET_LECTURE_WRONG_QUESTIONS
function* getLectureWrongQuestions(action) {
  try {
    const payload = yield call(api.getLectureWrongQuestions, action.lectureId);

    //
    // remove right questions
    let wrongQuestions = [];
    let allQuestions = [];
    let totalQuestions = 0;
    for (const [, questionItem] of Object.entries(payload)) {
      totalQuestions++;
      let addQuestion = true;
      questionItem.questao.respostas.forEach((answer) => {
        if (answer.correta === 1 && answer.marcada === true) addQuestion = false;
      });

      //
      // re-mount question object due pt-br answer key
      const question = Object.assign(
        { ...questionItem.questao },
        {
          answers: questionItem.questao.respostas.filter(
            (answer) => answer.resposta !== '' || answer.img_resposta !== null
          ),
          order: questionItem.ordem,
          wrong: addQuestion ? true : false,
        }
      );
      delete question.respostas;

      if (addQuestion) {
        wrongQuestions.push(question);
      }

      //
      // mouts allquestions array
      allQuestions.push(question);
    }

    wrongQuestions.sort((a, b) => a.order - b.order);
    allQuestions.sort((a, b) => a.order - b.order);

    yield put({
      type: constants.CLASS_GET_LECTURE_WRONG_QUESTIONS.SUCCESS,
      wrongQuestions,
      totalQuestions,
      allQuestions,
    });
    action.next && action.next(payload);
  } catch (e) {
    yield put({
      type: constants.CLASS_GET_LECTURE_WRONG_QUESTIONS.FAILED,
      message: e.message || e,
    });
  }
}

// CLASS_GET_FINISHED_MOCKS
function* getFinishedMocks(action) {
  try {
    const mockStructure = [];
    for (var mockItem of action.mocks) {
      const payload = yield call(api.getFinishedLectures, mockItem.id);
      mockStructure.push({
        ...mockItem,
        finishedMocks: payload,
      });
    }

    yield put({
      type: constants.CLASS_GET_FINISHED_MOCKS.SUCCESS,
      mockStructure,
    });
    action.next && action.next(mockStructure);
  } catch (e) {
    yield put({
      type: constants.CLASS_GET_FINISHED_MOCKS.FAILED,
      message: e.message || e,
    });
  }
}

function* watchGetLectureDetails() {
  yield takeEvery(constants.CLASS_GET_LECTURE_DETAILS.ACTION, getLectureDetails);
}

function* watchPersistOngoingLecture() {
  yield takeEvery(constants.CLASS_PERSIST_ONGOING_LECTURE.ACTION, persistOngoingLecture);
}

function* watchGetOngoingLecture() {
  yield takeEvery(constants.CLASS_GET_ONGOING_LECTURE.ACTION, getOngoingLecture);
}

function* watchFinishLecture() {
  yield takeEvery(constants.CLASS_FINISH_LECTURE.ACTION, finishLecture);
}

function* watchRegisterPdfDownload() {
  yield takeEvery(constants.CLASS_PDF_DOWNLOAD.ACTION, registerPdfDownload);
}

function* watchRegisterVideoPlay() {
  yield takeEvery(constants.CLASS_VIDEO_PLAY.ACTION, registerVideoPlay);
}

function* watchGetFinishedLectures() {
  yield takeEvery(constants.CLASS_GET_FINISHED_LECTURES.ACTION, getFinishedLectures);
}

function* watchGetLectureWrongQuestions() {
  yield takeEvery(constants.CLASS_GET_LECTURE_WRONG_QUESTIONS.ACTION, getLectureWrongQuestions);
}

function* watchGetFinishedMocks() {
  yield takeEvery(constants.CLASS_GET_FINISHED_MOCKS.ACTION, getFinishedMocks);
}

/**
 * Export the root saga by forking all available sagas.
 */
export function* rootSaga() {
  yield all([
    fork(watchGetLectureDetails),
    fork(watchPersistOngoingLecture),
    fork(watchGetOngoingLecture),
    fork(watchFinishLecture),
    fork(watchRegisterPdfDownload),
    fork(watchRegisterVideoPlay),
    fork(watchGetFinishedLectures),
    fork(watchGetLectureWrongQuestions),
    fork(watchGetFinishedMocks),
  ]);
}
