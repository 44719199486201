import { all, fork, call, put, takeEvery, select } from 'redux-saga/effects';

import { constants } from '../modules/course';
import * as api from '../api/course';

import * as courseSelectors from '../selectors/course';
import { notification } from 'antd';
// COURSE_GET_COURSES_BY_USER_ID
function* getCoursesByUserId(action) {
  try {
    const payload = yield call(api.getCoursesByUserId);
    yield put({
      type: constants.COURSE_GET_COURSES_BY_USER_ID.SUCCESS,
      payload,
    });
    action.next && action.next(payload);
  } catch (e) {
    yield put({
      type: constants.COURSE_GET_COURSES_BY_USER_ID.FAILED,
      message: e.message || e,
    });
    // notification['error']({
    //   message: `Problemas ao listar os cursos, por favor contate o administrador.`,
    // });

    action.error && action.error();
  }
}

function* getCourseDetails(action) {
  try {
    const payload = yield call(api.getCourseDetails, action.courseId, action.courseClassId);
    yield put({
      type: constants.COURSE_GET_DETAILS.SUCCESS,
      payload,
    });

    action.next && action.next(payload);
  } catch (e) {
    yield put({
      type: constants.COURSE_GET_DETAILS.FAILED,
      message: e.message || e,
    });
    notification['error']({
      message: `Problemas ao listar os detalhes do curso, por favor contate o administrador.`,
    });
  }
}

function* getCourseDetailsByTag(action) {
  try {
    const payload = yield call(api.getCourseDetailsByTag, action.courseTag);
    yield put({
      type: constants.COURSE_GET_DETAILS_BY_TAG.SUCCESS,
      payload,
    });
    action.next && action.next(payload);
  } catch (e) {
    yield put({
      type: constants.COURSE_GET_DETAILS_BY_TAG.FAILED,
      message: e.message || e,
    });
    notification['error']({
      message: `Problemas ao listar os detalhes do curso, por favor contate o administrador.`,
    });
  }
}

function* setClassProperties(action) {
  const classById = yield select(courseSelectors.getClassById);
  const selectedClass = classById(action.selectedClass.id);

  action.next && action.next(selectedClass);
}

function* watchGetCoursesByUserId() {
  yield takeEvery(constants.COURSE_GET_COURSES_BY_USER_ID.ACTION, getCoursesByUserId);
}

function* watchGetCourseDetails() {
  yield takeEvery(constants.COURSE_GET_DETAILS.ACTION, getCourseDetails);
}

function* watchGetCourseDetailsByTag() {
  yield takeEvery(constants.COURSE_GET_DETAILS_BY_TAG.ACTION, getCourseDetailsByTag);
}

function* watchSetClassStepCompleted() {
  yield takeEvery(constants.COURSE_UPDATE_CLASS.ACTION, setClassProperties);
}

/**
 * Export the root saga by forking all available sagas.
 */
export function* rootSaga() {
  yield all([
    fork(watchGetCoursesByUserId),
    fork(watchGetCourseDetails),
    fork(watchGetCourseDetailsByTag),
    fork(watchSetClassStepCompleted),
  ]);
}
